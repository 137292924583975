import { PageProps } from 'gatsby';
import { FeatureData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Feature';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/feature.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useParams from '~/utils/useParams';

const PageEspaceParametresFeatures: FC<
  PageProps & EspaceProps & UserProps
> = props => {
  const {
    location,
    espace,
    user,
    params: { espaceId },
  } = props;
  const { t } = useTranslation();
  const items = useParams({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/params/features/`}
          model={
            new FeatureData({
              espaceId,
              params,
              wheres: { parent: '' },
            })
          }
          pathname={location.pathname}
          search={location.search}
          subMenu={items}
          title={t('params.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceParametresFeatures);
